/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo, Suspense } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate, useSearchParams, useMatches } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components

// Material Dashboard 2 React themes
// import theme from "assets/theme-widget";
import theme from "assets/theme-dark";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator, doAsync } from "context";

import supabase from "components/Supabase/supabaseClient";

import "./App.css"
import { useTranslation, Trans } from "react-i18next";
import { loadUserConfig, loadTopics } from "actions/welliscoActions";
import _ from "lodash"

import InsureTaxPanel from "insuretax/panel";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [userOK, setUserOK] = useState(undefined)
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const taxPath = "/tax"
  const authPath = "/auth"
  const widgetPath = "/widget"
  const storyPath = "/story"
  const publicPathes = [authPath, widgetPath, storyPath]

  const isAuthPage = (pathname == authPath)

  const urlParams = new URLSearchParams(window.location.search);
  const urlCardStr = urlParams.get('card')
  var urlCardJson
  try {
    urlCardJson = urlCardStr && JSON.parse(urlCardStr)
  }
  catch (e) { }

  // // Supabase login 
  // useEffect(async () => {
  //   if (_.includes(publicPathes, pathname) == false) {
  //     const { data: { user } } = await supabase.auth.getUser()
  //     if (user)
  //       setUserOK(true)
  //     else {
  //       navigate('/auth');
  //     }
  //   }
  // });

  // useEffect(async () => {
  //   doAsync(dispatch, "userConfig", loadUserConfig)
  //   doAsync(dispatch, "topics", loadTopics)
  // }, []);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={`${route.route}/*`} element={
          <Suspense
            fallback={<div></div>}
          >
            {route.component}
          </Suspense>} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return <ThemeProvider theme={theme}>
    <CssBaseline />
    <Routes>
      {getRoutes(routes)}
      <Route path="*" element={<Navigate to="/v3" />} />
    </Routes>
  </ThemeProvider>

}
