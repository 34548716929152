/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// const TopicsPanel = React.lazy(() => import("./TopicsPanel"))

// Material Dashboard 2 React layouts
import { lazy } from 'react';
// import Dashboard from "layouts/dashboard";
// import Tables from "layouts/tables";
// import Billing from "layouts/billing";
// import RTL from "layouts/rtl";
// import Notifications from "layouts/notifications";
// import Profile from "layouts/profile";
// import SignIn from "layouts/authentication/sign-in";
// import SignUp from "layouts/authentication/sign-up";


// @mui icons
import Icon from "@mui/material/Icon";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import DevicesIcon from '@mui/icons-material/Devices';
import PeopleIcon from '@mui/icons-material/People';
import MessageIcon from '@mui/icons-material/Message';
import StorageIcon from '@mui/icons-material/Storage';
import PsychologyIcon from '@mui/icons-material/Psychology';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import NewspaperIcon from '@mui/icons-material/Newspaper';

const InsureTaxPanel = lazy(() => import(/* webpackChunkName: "Insights" */ 'insuretax/panel'));;
const InsureTaxPanelV2 = lazy(() => import(/* webpackChunkName: "Insights" */ 'insuretax/panel/panel_v2'));;
const InsureTaxPanelV3 = lazy(() => import(/* webpackChunkName: "Insights" */ 'insuretax/panel/panel_v3'));;
const WelliscoFinPanelV3 = lazy(() => import(/* webpackChunkName: "Insights" */ 'wellisco/fin/fin_panel_v3'));;

const routes = [
  {
    type: "collapse",
    name: "Tax",
    longName: "Tax Assessment",
    key: "home",
    icon: <EmojiObjectsIcon />,
    route: "/home",
    component: <InsureTaxPanel />,
  },
  {
    type: "collapse",
    name: "V2",
    longName: "Tax Assessment",
    key: "home",
    icon: <EmojiObjectsIcon />,
    route: "/v2",
    component: <InsureTaxPanelV2 />,
  },
  {
    type: "collapse",
    name: "V3",
    longName: "Tax Assessment",
    key: "home",
    icon: <EmojiObjectsIcon />,
    route: "/v3",
    component: <InsureTaxPanelV3 />,
  },
  {
    type: "collapse",
    name: "Fin",
    longName: "Wellisco Fin",
    key: "fin",
    icon: <EmojiObjectsIcon />,
    route: "/fin",
    component: <WelliscoFinPanelV3 />,
  },
];

export default routes;
